define("intrati/utils/intrati-twilio", ["exports", "cuid"], function (_exports, _cuid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.attachTracks = attachTracks;
  _exports.attachTrack = attachTrack;
  _exports.getTracks = getTracks;
  _exports.participantConnected = participantConnected;
  _exports.detachParticipantTracks = detachParticipantTracks;
  _exports.detachTrack = detachTrack;
  _exports.leaveRoomWhenBrowsingAway = leaveRoomWhenBrowsingAway;

  function trackPublished(publication, uniqueId) {
    /*
    * To get more details about publication object
    * See -> https://media.twiliocdn.com/sdk/js/video/releases/2.0.0-beta10/docs/RemoteTrackPublication.html
    */
    if (publication.isSubscribed) {
      attachTrack(publication.track, uniqueId);
    }

    publication.on("subscribed", function (track) {
      attachTrack(track, uniqueId);
    });
    /**
     * Remove the remote participant media from the room
     * when they deactivate camera or microphone
     */

    publication.on("unsubscribed", function (track) {
      track.detach().forEach(function (element) {
        element.remove();
      });
    });
  }

  function createStructure(id) {
    var parser = new DOMParser();
    var mediaWrapper = document.getElementById("media-wrapper");
    var videoAndAudioContainer = "\n    <div class=\"\" id=\"media-container-".concat(id, "\">\n      <div id=\"media-content-").concat(id, "\">\n        <div id=\"video-content-").concat(id, "\"></div>\n        <div id=\"audio-content-").concat(id, "\"></div>\n      </div>\n    </div>\n  ");
    var html = parser.parseFromString(videoAndAudioContainer, "text/html");
    mediaWrapper.appendChild(html.body.firstChild);
  }

  function attachTracks(tracks, uniqueId) {
    createStructure(uniqueId);
    tracks.forEach(function (track) {
      attachTrack(track, uniqueId);
    });
  }

  function attachTrack(track, uniqueId) {
    if (track.kind == "audio") {
      var audioWrapper = document.getElementById("audio-content-".concat(uniqueId));
      audioWrapper.appendChild(track.attach());
    } else if (track.kind == "video") {
      var videoWrapper = document.getElementById("video-content-".concat(uniqueId));
      videoWrapper.appendChild(track.attach());
    }
  }

  function getTracks(participant) {
    return Array.from(participant.tracks.values()).filter(function (publication) {
      return publication.track;
    }).map(function (publication) {
      return publication.track;
    });
  }

  function participantConnected(participant) {
    var id = (0, _cuid.default)();
    createStructure(id);
    participant.tracks.forEach(function (publication) {
      trackPublished(publication, id);
    });
    participant.on("trackPublished", function (publication) {
      trackPublished(publication, id);
    });
    participant.on("trackUnpublished", function (publication) {
      console.info("Track desactivado", publication);
    });
  }

  function detachParticipantTracks(participant) {
    var tracks = getTracks(participant);
    tracks.forEach(detachTrack);
  }

  function detachTrack(track) {
    track.detach().forEach(function (element) {
      element.remove();
    });
  }

  function leaveRoomWhenBrowsingAway(room) {
    /**
     * Listen to the "beforeunload" event on window to leave the Room
     * when the tab/browser is being closed.
     */
    window.addEventListener('beforeunload', function () {
      return room.disconnect();
    });
    /**
     * iOS Safari does not emit the "beforeunload" event on window.
     * Use "pagehide" instead.
     */

    window.addEventListener('pagehide', function () {
      return room.disconnect();
    });
  }
  /**
   * Remove the HTML structure created by `createStructure(id)`
   * after a remoteParticipant leaves a room.
   */


  function cleanUpEmptyContainers() {}
});