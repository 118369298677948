define("intrati/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xeauvNYR",
    "block": "{\"symbols\":[],\"statements\":[[9,\"div\",true],[12,\"class\",\"container mx-auto px-6 lg:px-12 pt-2 pb-8\",null],[10],[1,1,0,0,\"\\n  \"],[7,\"header\",[],[[],[]],null],[1,1,0,0,\"\\n\\n  \"],[9,\"div\",true],[12,\"class\",\"body lg:px-20\",null],[10],[1,1,0,0,\"\\n    \"],[1,0,0,0,[31,0,0,[27,[26,1,\"CallHead\"],[]],[[31,0,0,[27,[26,0,\"CallHead\"],[]],null,null]],null]],[1,1,0,0,\"\\n  \"],[11],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "intrati/templates/application.hbs"
    }
  });

  _exports.default = _default;
});