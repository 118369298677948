define("intrati/templates/room-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eRNBBlSs",
    "block": "{\"symbols\":[],\"statements\":[[9,\"p\",true],[12,\"class\",\"text-center\",null],[10],[1,1,0,0,\"\\n  Create a video meeting room for \"],[9,\"strong\",true],[10],[1,1,0,0,\"up to 4 people\"],[11],[1,1,0,0,\".\\n\"],[11],[1,1,0,0,\"\\n\\n\"],[7,\"room-form\",[],[[],[]],null]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "intrati/templates/room-form.hbs"
    }
  });

  _exports.default = _default;
});