define("intrati/utils/alerts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.showErrorAlert = showErrorAlert;

  function showErrorAlert(message) {
    document.getElementById('preview-room-error-message').innerText = message;
    document.getElementById('preview-room-error').classList.remove('hidden');
  }
});