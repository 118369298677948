define("intrati/utils/randomname", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.randomName = randomName;
  var ADJECTIVES = ["Abrasive", "Brash", "Callous", "Daft", "Eccentric", "Fiesty", "Golden", "Holy", "Ignominious", "Joltin", "Killer", "Luscious", "Mushy", "Nasty", "OldSchool", "Pompous", "Quiet", "Rowdy", "Sneaky", "Tawdry", "Unique", "Vivacious", "Wicked", "Xenophobic", "Yawning", "Zesty"];
  var FIRST_NAMES = ["Anna", "Bobby", "Cameron", "Danny", "Emmett", "Frida", "Gracie", "Hannah", "Isaac", "Jenova", "Kendra", "Lando", "Mufasa", "Nate", "Owen", "Penny", "Quincy", "Roddy", "Samantha", "Tammy", "Ulysses", "Victoria", "Wendy", "Xander", "Yolanda", "Zelda"];
  var LAST_NAMES = ["Anchorage", "Berlin", "Cucamonga", "Davenport", "Essex", "Fresno", "Gunsight", "Hanover", "Indianapolis", "Jamestown", "Kane", "Liberty", "Minneapolis", "Nevis", "Oakland", "Portland", "Quantico", "Raleigh", "SaintPaul", "Tulsa", "Utica", "Vail", "Warsaw", "XiaoJin", "Yale", "Zimmerman"];

  function randomItem(array) {
    var randomIndex = Math.floor(Math.random() * array.length);
    return array[randomIndex];
  }

  function randomName() {
    return randomItem(ADJECTIVES) + randomItem(FIRST_NAMES) + randomItem(LAST_NAMES);
  }
});