define("intrati/templates/create-room", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "y8cHMEBu",
    "block": "{\"symbols\":[],\"statements\":[[9,\"div\",true],[12,\"class\",\"hidden bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative\",null],[12,\"role\",\"alert\",null],[12,\"id\",\"preview-room-error\",null],[10],[1,1,0,0,\"\\n  \"],[9,\"strong\",true],[12,\"class\",\"font-bold\",null],[10],[1,1,0,0,\"\\n    Something went wrong\\n  \"],[11],[1,1,0,0,\"\\n  \"],[9,\"span\",true],[12,\"class\",\"block sm:inline\",null],[12,\"id\",\"preview-room-error-message\",null],[10],[1,1,0,0,\"\\n\\n  \"],[11],[1,1,0,0,\"\\n  \"],[9,\"span\",true],[12,\"class\",\"absolute top-0 bottom-0 right-0 px-4 py-3\",null],[10],[1,1,0,0,\"\\n    \"],[9,\"svg\",true],[12,\"class\",\"fill-current h-6 w-6 text-red-500\",null],[12,\"role\",\"button\",null],[12,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[12,\"viewBox\",\"0 0 20 20\",null],[10],[1,1,0,0,\"\\n      \"],[9,\"title\",true],[10],[1,1,0,0,\"Close\"],[11],[1,1,0,0,\"\\n      \"],[9,\"path\",true],[12,\"d\",\"M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z\",null],[10],[11],[1,1,0,0,\"\\n    \"],[11],[1,1,0,0,\"\\n  \"],[11],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\\n\"],[7,\"create-room\",[],[[],[]],null]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "intrati/templates/create-room.hbs"
    }
  });

  _exports.default = _default;
});