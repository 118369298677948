define("intrati/components/header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <header class="flex justify-center p-4 mb-4">
    <span class="text-lg">
      <a href="/">Intrati</a>
    </span>
  </header>
  
  */
  {
    id: "NMIa4NH0",
    block: "{\"symbols\":[],\"statements\":[[9,\"header\",true],[12,\"class\",\"flex justify-center p-4 mb-4\",null],[10],[1,1,0,0,\"\\n  \"],[9,\"span\",true],[12,\"class\",\"text-lg\",null],[10],[1,1,0,0,\"\\n    \"],[9,\"a\",true],[12,\"href\",\"/\",null],[10],[1,1,0,0,\"Intrati\"],[11],[1,1,0,0,\"\\n  \"],[11],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    meta: {
      moduleName: "intrati/components/header.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});